// ** @jsx jsx *
import React, { useState, useEffect, useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"
import Layout from "../components/Layout"
import Header from "../components/ui/Header"
import ProductMaster from "../components/productTemplateComponents/ProductMaster.jsx"
import ReactPixel from "../lib/react-facebook-pixel"
import { useStaticQuery, graphql } from "gatsby"
const ProductTemplate = ({ pageContext, location }) => {
  const { sanityIntegration } = useStaticQuery(graphql`
    query ProductPixelFacebook {
      sanityIntegration {
        facebookPixel {
          pixelId
          currency
        }
      }
    }
  `)
  const useTheme = useThemeUI()
  const colorBackground =
    useTheme &&
    useTheme.theme &&
    useTheme.theme.colors &&
    useTheme.theme.colors.background
      ? useTheme.theme.colors.background.replace("#", "")
      : "ffffff"
  const { product } = pageContext
  const imgTransform =
    product && product.img && product.img.asset && product.img.asset.url
      ? `${product.img.asset.url}/?bg=${colorBackground}&h=250&w=250`
      : ""
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (
        sanityIntegration &&
        sanityIntegration.facebookPixel &&
        sanityIntegration.facebookPixel &&
        sanityIntegration.facebookPixel.pixelId
      ) {
        ReactPixel.track("ViewContent", {
          whatshop_content_type: "product",
          whatshop_content_id: product.id,
          whatshop_content_title: product.title,
          whatshop_product: product.title,
          whatshop_product_id: product.id,
          value: product.price,
          currency: sanityIntegration.facebookPixel.currency,
        })
      }
    }
  }, [])
  return (
    <Layout
      seo={{
        title: product.title,
        description: product.description,
        image: imgTransform,
      }}
    >
      <Header
        prevPage={
          (location && location.state && location.state.prevPage) || "/"
        }
      />
      <ProductMaster product={product} />
    </Layout>
  )
}

export default ProductTemplate
